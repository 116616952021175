<template>
    <div class=container v-if="bloaded && aloaded">
        <p class=title > Bibtex Entry for {{bibtex[paper].cite}}</p>
        <br>
        <br>
        <div class=info>
        <!-- APA format -->
        <b >{{apa[paper].title}}<br> </b>
        <span v-for="line in apa[paper].rest" :key="line">{{line}}<br> </span>
        <br>
        <br>
        <!-- Bibtex format -->
        <span v-for="line in bibtex[paper].rest" :key="line">{{line}}<br> </span>
        </div>
    </div>
</template>

<script>
import axios from "axios"
export default {
    data(){
        return{
            paper : this.paper,
            apa : this.apa,
            bibtex : this.bibtex,
            aloaded : this.aloaded,
            bloaded : this.bloaded
        }
    },
    created(){
        this.paper = this.$route.query.bid,

        //     axios.get("http://localhost:8080/api/Statics/publications/APA.json").then((res)=>{
        //     this.apa = res.data
        //     this.aloaded = true
        // }).then(
        // axios.get("http://localhost:8080/api/Statics/publications/Bibtex.json").then((res)=>{
        //     this.bibtex = res.data
        //     this.bloaded = true
        // }))



        axios.get("https://cave.cs.columbia.edu/Statics/publications/APA.json").then((res)=>{
            this.apa = res.data
            this.aloaded = true
        }),
        axios.get("https://cave.cs.columbia.edu/Statics/publications/Bibtex.json").then((res)=>{
            this.bibtex = res.data
            this.bloaded = true
        })
    }
}
</script>

<style scoped>
.container{
    margin:auto;
    width:60% ;
}
.title{
    font-weight: bold;
    /* font-family:'Raleway', sans-serif; */
}
.info{
    text-align: left;
}
</style>